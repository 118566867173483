
@keyframes faderowout {
    from {
        opacity: 1;
        height: 50px;
    }
    to {
        opacity: 0;
        height: 0px;
    }
}

.red {
    color: #a00;
    cursor: pointer;
}
.orow {
    padding: 8px 5px;
    background: #fff;
    color:#666;
    border-bottom: 1px solid #ccc;
}
.orow .selected {
    background: #00a900;
    color:#ffffff;
}
.orow:hover {
    background: #ff9900;
    color: #ffffff;
}
.odesc {
    font-size: 12px;
}
.foodle-top {
    background-color: rgb(244, 59, 36);
    background-color: #2e9b88;
    padding: 0px;
}
.foodle-mv {
    animation-duration: 2s;
    animation-name: faderowout;
}
@keyframes fmvout {
    from {
        opacity: 0;
        height: 50px;
    }
    to {
        opacity: 1;
        height: 50px;
    }
}
.fmv0 {
    height: 50px;
    opacity: 1;
    transition: height .5s, opacity .5s;
}
.fmvz {
    height: 50px;
    opacity: 1;
    animation: 0.5s ease-out 0s 1 fmvout;
}
.fmv1 {
    height: 0px;
    opacity: 0;
}

.foodle-foot {
    background-color: rgb(244, 59, 36);
    padding: 0px;
    min-height: 200px;
}
.foodle-unit {
    padding: 5px 5px;
    min-width: 250px;
    min-height: 150px;
    background-color: #fff;
    color: #222;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 0px solid #222;
    border-radius: 10px;
}
.foodle-unit:hover {
    background-color: #ff9900;
    color: #fff;
}

.mg-img {
    width: 300px;
    height: 200px;
}

.mg-txt-body {
    padding: 25px 5px;
    margin: 5px 15px;
    opacity: 90%;
    background: #fff;
    color: #000;
    border-radius: 8px;
}
.mg-noimg {
    width: 300px;
    height: 250px;
    padding: 25px 5px;
}
.mg-burger {
    background-image: url("https://story.svepos.se/foodle/burgers_lg.png");
    background-size: cover;
    width: 300px;
    padding: 25px 5px;
}
.mg-pizza {
    background-image: url("https://story.svepos.se/foodle/pizza_lg.png");
    background-size: cover;
    width: 300px;
    padding: 25px 5px;
}

.mg-pasta {
    background-image: url("https://story.svepos.se/foodle/pasta_lg.png");
    background-size: cover;
    width: 300px;
    padding: 25px 5px;
}
.mg-sushi {
    background-image: url("https://story.svepos.se/foodle/sushi_lg.png");
    background-size: cover;
    width: 300px;
    padding: 25px 5px;
}
.mg-taco {
    background-image: url("https://story.svepos.se/foodle/taco_lg.png");
    background-size: cover;
    width: 300px;
    padding: 25px 5px;
}
.mg-kebab {
    background-image: url("https://story.svepos.se/foodle/kebab_lg.png");
    background-size: cover;
    width: 300px;
    padding: 25px 5px;
}
.mg-wok {
    background-image: url("https://story.svepos.se/foodle/wok_lg.png");
    background-size: cover;
    width: 300px;
    padding: 25px 5px;
}
.mg-sallad {
    background-image: url("https://story.svepos.se/foodle/sallad_lg.png");
    background-size: cover;
    width: 300px;
    padding: 25px 5px;
}
.mg-husman {
    background-image: url("https://story.svepos.se/foodle/husman_lg.png");
    background-size: cover;
    width: 300px;
    padding: 25px 5px;
}
.mg-sides {
    background-image: url("https://story.svepos.se/foodle/sides_lg.png");
    background-size: cover;
    width: 300px;
    padding: 25px 5px;
}
.mg-kiosk {
    background-image: url("https://story.svepos.se/foodle/kiosk_lg.png");
    background-size: cover;
    width: 300px;
    padding: 25px 5px;
}
.mg-pic-sides {
    padding: 25px 5px;
}
.mg-pic-sides div {
    background-image: url("https://story.svepos.se/foodle/sides_lg.png");
    background-size: cover;
    width: 300px;
    height: 200px;
}
.mg-pic-kiosk {
    padding: 25px 5px;
}
.mg-pic-kiosk div {
    background-image: url("https://story.svepos.se/foodle/kiosk_lg.png");
    background-size: cover;
    width: 300px;
    height: 200px;
    padding: 25px 5px;
}

.mgb-burgers {
    margin: 0px auto;
    padding: 100px 0px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/burgers_lg.png");
    background-size: cover;
    padding: 25px 5px;
}

.mgb-pizza {
    margin: 0px auto;
    padding: 100px 20px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/pizza_lg.png");
    background-size: cover;
}

.mgb-pasta {
    margin: 0px auto;
    padding: 100px 20px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/pasta_lg.png");
    background-size: cover;
}

.mgb-sushi {
    margin: 0px auto;
    padding: 100px 20px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/sushi_lg.png");
    background-size: cover;
}

.mgb-taco {
    margin: 0px auto;
    padding: 100px 20px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/taco_lg.png");
    background-size: cover;
}

.mgb-kebab {
    margin: 0px auto;
    padding: 100px 20px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/kebab_lg.png");
    background-size: cover;
}

.mgb-wok {
    margin: 0px auto;
    padding: 100px 20px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/wok_lg.png");
    background-size: cover;
}

.mgb-sallad {
    margin: 0px auto;
    padding: 100px 20px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/sallad_lg.png");
    background-size: cover;
}
.mgb-husman {
    margin: 0px auto;
    padding: 100px 20px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/husman_lg.png");
    background-size: cover;
}
.mgb-sides {
    margin: 0px auto;
    padding: 100px 20px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/sides_lg.png");
    background-size: cover;
}
.mgb-kiosk {
    margin: 0px auto;
    padding: 100px 20px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/kiosk_lg.png");
    background-size: cover;
}
.foodle-img {
    height: auto;
    margin: auto;
    padding-top: 48px;
}

.foodle-txt {
    height: 55px;
    padding: 5px 5px;
    object-fit: contain;
}
.foodle-menu {
    height: 75px;
    padding: 5px 5px;
}
.foodle-mmenu {
    position:relative;
    padding: 0px;
    z-index: 200;
    height: 55px;
    line-height: 55px;
}
.foodle-mobile-mwnd {
    position:absolute;
    top: 0px;
    left: 0px;
    margin: 0px;
    padding: 20px;
    width: 100%;
    height: 100%;
    background-color: rgb(244, 59, 36);
    background-color: #2e9b88;
    color: #fff;
    z-index: 1150;
}
.foodle-mobile {
    position:relative;
    z-index: 1150;
}
.foodle-mobile-btn {
    position:absolute;
    right: 20px;
    top: 20px;
    margin: 0px;
    padding: 0px;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    z-index: 1150;
}

.foodle-check {
    height: 15px;
    padding: 0px;
}
.foodle-ul {
    list-style: none;
    font-size: 18px;
}
.foodle-ul a {
    text-decoration: none;
}
.foodle-horiz-ul {
    list-style: none;
    font-size: 18px;
}
.foodle-horiz-ul li {
    float: left;
    padding: 0px 5px;
}
.foodle-horiz-ul a {
    text-decoration: none;
}

.foodle-vert-ul {
    list-style: none;
    font-size: 18px;
}
.foodle-vert-ul li {
    padding: 0px 5px;
}
.foodle-vert-ul a {
    text-decoration: none;
}

.divider_gray {
    margin: 0px 10px;
    border: 1px dashed #999;
}

.foodle-box {
    padding: 0px 20px;
    height: 75px;
    line-height: 75px;
    border: 1px solid #999;
    border-radius: 5px;
    text-align: left;
}
.foodle-box:hover {
    background: #fff;
}
.lev-box {
    padding: 5px 20px;
    width: 100%;
    line-height: 30px;
    border: 1px solid #999;
    border-radius: 5px;
    text-align: left;
    font-size: 16px;
    color: #333;
}

.foodle-input {
    padding: 0px 8px;
    height: 45px;
    line-height: 45px;
    border: 1px solid #999;
    border-radius: 5px;
    font-size: 18px;
    color: #222;
}
.cart-item {
    border-bottom: 1px dashed #555;
}
.box-lev {
    padding: 5px 8px;
    background: #f5f0eb;
    color: #222;
    border-radius: 5px;
    min-height: 50px;
}
.box-adyen {
    padding: 5px 8px;
    background: #ff9900;
    color: #fff;
    border: 1px solid #555;
    border-radius: 5px;
    min-height: 150px;
}

.font-colby {
    font-family: "Colby",Fallback,sans-serif;
}
.font-feltthat {
    font-family: "FeltThat",Fallback,sans-serif;
}
.fw100 {
    font-weight: 100;
}
.fw700 {
    font-weight: 700;
}
.fbold {
    font-weight: 700;
}
.f12 {
    font-size: 12px;
}
.f14 {
    font-size: 14px;
}
.f16 {
    font-size: 16px;
}
.f22 {
    font-size: 22px;
}
.f32 {
    font-size: 32px;
}
.f50 {
    font-size: 50px;
}
.f80 {
    font-size: 80px;
}
.f100 {
    font-size: 100px;
}
.f120 {
    font-size: 120px;
}
.lh120 {
    line-height: 120px;
}
.foodle-bg {
    background-color: rgb(244, 59, 36);
    background-color: #2e9b88;
    color: #fff;
}
.fslide0 {
    height: 50px;
    transition: height .5s, opacity .5s;
}
.fslide1 {
    height: 0px;
    opacity: 0;
    font-family: 'Brown', Fallback, sans-serif;
    font-size:14px;
}
.hsx {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(9, calc(40% - 40px));
    grid-template-rows: minmax(150px, 1fr);
    padding: 0 20px;
    overflow: hidden;
}
.hs {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
    overflow: hidden;
}
.ftt {
    height: 0px;
    font-family: 'FeltThat', Fallback, sans-serif;
    font-size:14px;
}
.bg_home_01 {
    margin: 0px auto;
    //padding: 100px 20px;
    width: 100%;
    background-image: url("https://story.svepos.se/foodle/foodle_hero_startsida.jpeg");
    background-size: cover;
}
.foodle_bottom_logo {
    text-align: left;
}
.foodle_circle {
    margin: 5px;
    padding: 20px;
    color: #222;
    background: #fff;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.foodle_circle_shadow {
    margin: 5px;
    box-shadow: 0px 0px 15px #eee;
}
.foodle_circle:hover {
    color: #222;
    background: #ddd;
}
@media (max-width: 800px) {
    .foodle-ul {
        text-align: center;
    }
    .mg-noimg {
        margin: auto;
    }
    .mg-pic-sides {
        margin: auto;
    }
    .mg-pic-kiosk {
        margin: auto;
    }
    .foodle-img {
        height: auto;
        margin: auto;
        padding-top: 32px;
        padding-left: 5px;
        padding-right: 5px;
        max-width: 100%;
    }
    .bg_home_01 {
        margin: 0px auto;
        //padding: 100px 20px;
        width: 100%;
        background-image: url("https://story.svepos.se/foodle/foodle_hero_startsida.jpeg");
        background-size: auto;
        background-position: -550px -100px;
    }
    .foodle_bottom_logo {
        padding-bottom: 50px;
        text-align: center;
    }
    .mg-burger {
        width: 100%;
        text-align: center;
    }
    .mg-pizza {
        width: 100%;
        text-align: center;
    }
    .mg-pasta {
        width: 100%;
        text-align: center;
    }
    .mg-sushi {
        width: 100%;
        text-align: center;
    }
    .mg-taco {
        width: 100%;
        text-align: center;
    }
    .mg-kebab {
        width: 100%;
        text-align: center;
    }
    .mg-wok {
        width: 100%;
        text-align: center;
    }
    .mg-sallad {
        width: 100%;
        text-align: center;
    }
    .mg-husman {
        width: 100%;
        text-align: center;
    }
    .mg-sides {
        width: 100%;
        text-align: center;
    }
    .mg-kiosk {
        width: 100%;
        text-align: center;
    }
    .f120 {
        font-size: 60px;
    }
    .f80 {
        font-size: 40px;
    }
    .lh120 {
        line-height: 60px;
    }
    .faq {
        font-size: 14px;
    }
    .txt_20 {
        font-size: 18px;
    }
    .txt_16 {
        font-size: 14px;
    }
    .txt_18 {
        font-size: 18px;
    }
    .black {
        color: #000;
    }
    .hs {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(1, 1fr);
        padding: 0;
        overflow: hidden;
    }
    .hsx {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: minmax(150px, 1fr);
        padding: 0 20px;
        overflow: auto;
    }
    .foodle_circle {
        margin: 2px;
        padding: 5px;
        color: #222;
        background: #fff;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
    }
    .foodle_circle_shadow {
        margin: 2px;
        box-shadow: 0px 0px 15px #eee;
    }
}
