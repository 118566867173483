.shoptheme {
    background-color: #000;
    color: #fff;
}
.shoptheme .mx_dlg {
    background-color: #fff;
    color: #000;
}
.shoptheme .m2_dlg {
    background-color: #fff;
    color: #000;
}

.shop-01 .mcom-top {
    background-color: #fff;
    color: #000;
    padding: 0px;
}
.shop-02 .mcom-top {
    background-color: #000;
    color: #fff;
    padding: 0px;
}
.shop-06 .mcom-top {
    background-color: #2e9b88;
    color: #fff;
    padding: 0px;
}
.shop-11 .mcom-top {
    background-color: #000;
    color: #fff;
    padding: 0px;
}

.shop-11 {
    background-color: #000;
    color: #fff;
}
.shop-11 .mcom-top {
    background-color: #000;
    color: #fff;
    padding: 0px;
}
.shop-11 .mx_dlg {
    background-color: #fff;
    color: #000;
}
.shop-11 .m2_dlg {
    background-color: #fff;
    color: #000;
}

.shop-01 {
    background-color: #fff;
    color: #000;
}
.shop-01 .mx_dlg {
    background-color: #fff;
    color: #000;
}
.shop-01 .m2_dlg {
    background-color: #fff;
    color: #000;
}
.shop-01 .web-btn {
    background-color: #fff;
    color: #000;
    border: 1px solid #222;
}
.shop-01 .mnu_item {
    background-color: #fff;
    color: #000;
}
.shop-01 .mnu_item_head {
    color: #000;
}
.shop-01 .foot-btn {
    background-color: #fff;
    color: #000;
}
.shop-01 .foot-btn {
    background-color: #fff;
    color: #000;
}

.shop-02 {
    background-color: #000;
    color: #fff;
}
.shop-02 .mx_dlg {
    background-color: #000;
    color: #fff;
}
.shop-02 .m2_dlg {
    background-color: #000;
    color: #fff;
}

.shop-03 {
    background-color: #567cde;
    color: #ecebe6;
}
.shop-03 .mx_dlg {
    background-color: #000;
    color: #fff;
}
.shop-03 .m2_dlg {
    background-color: #000;
    color: #fff;
}

.shop-04 {
    background-color: #fff;
    color: #000;
}
.shop-04 .mx_dlg {
    background-color: #fff;
    color: #000;
}
.shop-04 .m2_dlg {
    background-color: #fff;
    color: #000;
}
.shop-04 .mnu_item {
    min-height: 75px;
    padding: 8px 8px;
    color: #777;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
}
.shop-04 .mnu_item:hover {
    color: #ff9900;
    background: #e5e5e5;
}
.shop-04 .mnu_item_head {
    color: #777;
}
.shop-04 .mnu-link {
    color: #c1d0f5;
}
.shop-04 .web-btn {
    background-color: #e0e0e0;
    color: #222;
}

.shop-05 {
    background-color: #f43b24;
    color: #fff;
}
.shop-05 .mx_dlg {
    background-color: #f43b24;
    color: #fff;
}
.shop-05 .m2_dlg {
    background-color: #f43b24;
    color: #fff;
}
.shop-05 .mnu_item {
    min-height: 75px;
    padding: 8px 8px;
    color: #777;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
}
.shop-05 .mnu_item:hover {
    color: #ff9900;
    background: #e5e5e5;
}
.shop-05 .mnu_item_head {
    color: #777;
}
.shop-05 .mnu-link {
    color: #c1d0f5;
}
.shop-05 .web-btn {
    background-color: #e0e0e0;
    color: #222;
}

.shop-06 {
    background-color: #2e9b88;
    color: #fff;
}
.shop-06 .mx_dlg {
    background-color: #2e9b88;
    color: #fff;
}
.shop-06 .m2_dlg {
    background-color: #2e9b88;
    color: #fff;
}
.shop-06 .mnu_item {
    min-height: 75px;
    padding: 8px 16px;
    color: #454545;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
}
.shop-06 .mnu_item:hover {
    color: #ff9900;
    background: #e5e5e5;
}
.shop-06 .mnu_item_head {
    color: #333;
    font-weight: 700;
}
.shop-06 .mnu-link {
    color: #c1d0f5;
}
.shop-06 .web-btn {
    background-color: #e0e0e0;
    color: #222;
}

.shop-09 {
    background-color: #fff;
    color: #000;
}
.shop-09 .mx_dlg {
    background-color: #fff;
    color: #000;
}
.shop-09 .m2_dlg {
    background-color: #fff;
    color: #000;
}
.shop-09 .mnu_item {
    min-height: 75px;
    padding: 8px 8px;
    color: #555;
    background: #eaeaea;
    border-bottom: 1px solid #ccc;
}
.shop-09 .mnu_item:hover {
    color: #ff9900;
    background: #e5e5e5;
}
.shop-09 .mnu_item_head {
    color: #777;
}
.shop-09 .mnu-link {
    color: #c1d0f5;
}
.shop-09 .web-btn {
    background-color: #e0e0e0;
    color: #222;
}
.shop-09 .dish_item {
    min-height: 25px;
    padding: 8px 8px;
    color: #222;
    background: #eaeaea;
    border-bottom: 1px solid #ccc;
}
.shop-09 .dish_item:hover {
    color: #ff9900;
    background: #e5e5e5;
}
.shop-09 .dish_item_head {
    color: #222;
}
