html, body {
  font-family: 'Poppins', 'Garamond', 'Roboto', sans-serif;
  font-size:14px;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
#root {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.web-app {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  -webkit-flex: 1;
}
.flex {
  flex: 1;
}
.svk-logo {
  height: 50px;
}
.svk-mini-logo {
  height: 20px;
}
.m0-logo {
  height: 75px;
}
.web-head-1 {
  height: 40px;
  border-bottom: 1px solid #cccccc;
  color: #333;
  padding: 0px;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9010;
}
.web-topx {
  background-color: #114c7e;
  color: #eeeeee;
}
.web-header {
  height: 40px;
  border-bottom: 1px solid #cccccc;
  background-color: #0067c6;
  color: #eeeeee;
  padding: 0px;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9010;
}
.web-top-icon {
  line-height: 40px;
}
.web-body {
  padding-top: 5px;
  flex: 1;
  -webkit-flex: 1;
}
.back {
  color: #0067c6;
}
.web-content {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex: 1;
  -webkit-flex: 1;
  flex-direction: column;
  align-items: stretch;
  font-size: 14px;
}
.web-foot {
  border-top: 1px solid #555;
  background-color: rgb(255,255,255);
  color: #000;
  margin: 0px;
  padding: 0px;
}
.foot-btn {
  position: relative;
  background: #222;
  color: #cdcdcd;
  margin: 0px;
  padding: 0px;
  flex: 1;
  -webkit-flex: 1;
  height: 50px;
  line-height: 20px;
  border: 1px solid #555;
  text-align: center;
}

.foot-btn .selected {
  background: #080;
  color: #fff;
}
.foot-btn .selected svg {
  color: #fff;
}
.qrcdi {
  margin: 0px auto;
  padding: 0px;
  padding-top: 0px;
  width: 600px;
  height: 400px;
}
.qrcdi #video {
  position: relative;
}

.w20, .form-control.w20 {
  width: 20px;
}
.w25, .form-control.w25 {
  width: 20px;
}
.w40, .form-control.w40 {
    width: 40px;
}
.w50, .form-control.w50 {
    width: 50px;
}
.w60, .form-control.w60 {
  width: 60px;
}
.w75, .form-control.w75 {
  width: 75px;
}
.w100, .form-control.w100 {
    width: 100px;
}
.w150, .form-control.w150 {
    width: 150px;
}
.w200, .form-control.w200 {
    width: 200px;
}
.w250, .form-control.w250 {
    width: 250px;
}
.w300, .form-control.w300 {
    width: 300px;
}
.w350, .form-control.w350 {
    width: 350px;
}
.w400, .form-control.w400 {
    width: 400px;
}
.w450, .form-control.w450 {
    width: 450px;
}
.w500, .form-control.w500 {
    width: 500px;
}

.h100 {
    height: 100px;
}
.h150 {
    height: 150px;
}
.h200 {
    height: 200px;
}
.h250 {
    height: 250px;
}

.txt_12 {
  font-size: 12px;
}
.txt_14 {
  font-size: 14px;
}
.txt_18 {
  font-size: 18px;
}
.txt_20 {
  font-size: 20px;
}
.txt_22 {
  font-size: 22px;
}
.txt_24 {
  font-size: 24px;
}
.txt_26 {
  font-size: 26px;
}
.txt_28 {
  font-size: 28px;
}
.txt_30 {
  font-size: 30px;
}
.txt_32 {
  font-size: 32px;
}
.txt_38 {
  font-size: 38px;
}
.txt_42 {
  font-size: 42px;
}
.txt_b16 {
  font-size: 16px;
  font-weight: bold;
}
.txt_b18 {
  font-size: 18px;
  font-weight: bold;
}

.txt_left {
  margin: 0px;
  padding: 0px 5px;
  text-align: left;
}
.txt_right {
  margin: 0px;
  padding: 0px 5px;
  text-align: right;
}
.txt_center {
  text-align: center;
}
.cdi-logo {
  margin: 0px;
  padding: 0px;
  width: 200px;
  object-fit: contain;
}
.divider_grey {
  border-top: 1px solid #555;
}
.divider_orange {
  border-top: 1px solid #f99000;
}
.divider_blue {
	border-top: 1px solid #7777e1;
}
.divider_green {
	margin: 0px;
	padding: 0px;
	border-top: 1px solid #008800;
}
.horz_red {
	margin: 0px;
	padding: 0px;
	border-left: 1px solid #880000;
}
.vert_orange {
	margin: 0px;
	padding: 0px;
	border-left: 1px solid #f99000;
}

.ws-body {
  margin: 0px auto;
  padding: 0px;
  width: 1024px;
}
.ws-area {
  margin: 0px;
  padding: 5px 15px;
  min-height: 80%;
  background-color: #ffffff;
  color: #222;
  border: 1px solid #555;
  border-radius: 8px;
}
.ws-grp {
  margin: 0px;
  padding: 15px 25px;
  min-height: 150px;
  background-color: #ffffff;
  color: #222;
  border: 1px solid #555;
  border-radius: 8px;
}
.web-btn {
  margin: 3px;
  padding: 5px;
  height: 65px;
  line-height: 20px;
  background-color: #222;
  color: #dadada;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #555;
  border-radius: 5px;
}

.web-btn:hover {
  background-color: #444;
  color: #ff9900;
}
.web-btn-x1 {
  margin: 3px;
  padding: 5px;
  height: 65px;
  line-height: 20px;
  background-color: #800f0f;
  color: #dadada;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #555;
  border-radius: 5px;
}
.web-btn-x0 {
  margin: 3px;
  padding: 5px;
  height: 65px;
  line-height: 20px;
  background-color: transparent;
  color: #dadada;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 5px;
}

.web-hw-btn {
  margin: 3px;
  padding: 5px;
  height: 65px;
  line-height: 20px;
  background-color: transparent;
  color: #dadada;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ff9900;
  border-radius: 5px;
}

.web-btn-01 {
  margin: 5px;
  padding: 5px;
  height: 40px;
  line-height: 20px;
  background-color: #3f5edc;
  color: #dadada;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #555;
  border-radius: 5px;
}

.web-btn-01:hover {
  background-color: #444;
  color: #ff9900;
}
.web-btn-02 {
  margin: 5px;
  padding: 5px;
  height: 40px;
  line-height: 20px;
  background-color: #1f8d1f;
  color: #dadada;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #555;
  border-radius: 5px;
}

.web-btn-02:hover {
  background-color: #444;
  color: #ff9900;
}

.web-inp-11 {
  margin: 5px;
  padding: 5px;
  height: 40px;
  line-height: 20px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #555;
  border-radius: 5px;
}

.web-inp-21 {
  margin: 5px;
  padding: 5px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  font-size: 18px;
  background-color: transparent;
  color: #dadada;
  border: 1px solid #080;
  border-radius: 5px;
}

.web-btn-11 {
  margin: 5px;
  padding: 5px;
  height: 40px;
  line-height: 20px;
  background-color: #e19c37;
  color: #dadada;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #555;
  border-radius: 5px;
}
.web-hw-11 {
  margin: 5px;
  padding: 5px;
  height: 50px;
  line-height: 25px;
  background-color: transparent;
  color: #dadada;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ff9900;
  border-radius: 5px;
}
.web-hw-21 {
  margin: 5px;
  padding: 5px;
  height: 75px;
  line-height: 35px;
  background-color: transparent;
  color: #dadada;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ff9900;
  border-radius: 5px;
}
.web-hw-green {
  border: 1px solid #25f525;
}
.web-hw-31 {
  margin: 5px;
  padding: 5px;
  height: 100px;
  line-height: 50px;
  background-color: transparent;
  color: #dadada;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ff9900;
  border-radius: 5px;
}

.web-comment-11 {
  margin: 5px;
  padding: 5px;
  font-size: 18px;
  border: 1px solid #555;
  border-radius: 5px;
}

.web-kvitto {
  margin: 0px;
  padding: 5px;
  min-width: 150px;
  min-height: 250px;
  line-height: 20px;
  background-color: #fff;
  color: #222;
  text-align: center;
  border: 1px solid #555;
  border-radius: 5px;
}

.kursiv {
  font-style: italic;
}

.badge {
  position: absolute;
  top: -35%;
  right: 5%;
  border-radius: 50%;
  font-size: 18px;
  text-align: center;
  background: #d03d3d;
  color: #fefefe;
  opacity: 0.9;
}

.xbox {
  margin: 5px;
  padding: 5px;
  background-color: #dadada;
  color: #222;
  text-align: center;
  flex-direction: column;
  border: 1px solid #222;
  border-radius: 5px;
}