[draggable=true] {
    -webkit-user-drag: element;
    -khtml-user-drag: element;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-drag: element;
    user-select: none;
}

.mx_backdrop {
    position: absolute;
    left: 0;
    top: 0;
    background:#555;
    opacity: 0.6;
    min-width: 100%;
    min-height: 100%;
    z-index: 1015;
}
.m2_backdrop {
    position: absolute;
    left: 0;
    top: 0;
    background:#555;
    opacity: 0.6;
    min-width: 100%;
    min-height: 100%;
    z-index: 1024;
}
.m3_backdrop {
    position: absolute;
    left: 0;
    top: 0;
    background:#555;
    opacity: 0.6;
    min-width: 100%;
    min-height: 100%;
    z-index: 1029;
}
.m2_dlg {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    background:#fff;
    min-width: 50%;
    height: 50%;
    border: 1px solid #555;
    border-radius: 4px;
    z-index: 1025;

    box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
}
.m3_dlg {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    background:#fff;
    min-width: 50%;
    height: 50%;
    border: 1px solid #555;
    border-radius: 4px;
    z-index: 1030;

    box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
}
.mx_dlg {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    background:#fff;
    min-width: 50%;
    height: 50%;
    border: 1px solid #555;
    border-radius: 4px;
    z-index: 1020;

    box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
}
.mx_dlg_big {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    background:#fff;
    min-width: 90%;
    height: 90%;
    border: 1px solid #555;
    border-radius: 4px;
    z-index: 1020;
    animation: mx_dlg_big 1s;

    box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
}
.mx_dlg_lg {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    background:#fff;
    min-width: 95%;
    height: 95%;
    border: 1px solid #555;
    border-radius: 4px;
    z-index: 1020;
    animation: mx_dlg_big 1s;

    box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
}
.mx_dlg_max {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    background:#fff;
    min-width: 98%;
    height: 98%;
    border: 1px solid #555;
    border-radius: 4px;
    z-index: 1020;
    animation: mx_dlg_big 1s;

    box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
}
.mx_dlg_rpx {
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    background:#fff;
    min-width: 100%;
    height: 100%;
    border: 1px solid #555;
    border-radius: 5px;
    z-index: 1020;
}

.mx_fb {
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    background:#fff;
    min-width: 100%;
    height: 100%;
    border: 1px solid #555;
    border-radius: 5px;
    z-index: 1020;
}

@keyframes mx_dlg_bigx {
  0% { min-width: 0%; height: 0%; }
  50% { min-width: 70%; height: 70%; }
  100% { min-width: 90%; height: 90%; }
}
@keyframes mx_dlg_big {
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1.0; }
}
@media screen and (max-width: 800px) {
    .mx_dlg_big {
    left: 0;
    top: 0;
    margin-right: 0;
    transform: none;
    min-width: 100%;
    height: 100%;
    }
}
.mx_rp {
    min-width: 60%;
    height: 65%;
}

.mx_50 {
    min-width: 50%;
    height: 50%;
}
.mx_50h {
    min-width: 50%;
    height: 60%;
}
.mx_50hh {
    min-width: 50%;
    height: 80%;
}
.mx_60 {
    min-width: 60%;
    height: 60%;
}
.mx_60h {
    min-width: 60%;
    height: 70%;
}
.mx_70 {
    min-width: 70%;
    height: 70%;
}
.mx_70h {
    min-width: 70%;
    height: 80%;
}
.mx_75 {
    min-width: 75%;
    height: 75%;
}
.mx_80 {
    min-width: 80%;
    height: 80%;
}
.mx_80w {
    width: 80%;
    height: 80%;
}
.mx_80h {
    min-width: 80%;
    height: 95%;
}
.mx_85 {
    min-width: 85%;
    height: 85%;
}
.mx_90 {
    width: 90%;
    max-width: 90%;
    min-width: 90%;
    height: 90%;
}
.mx_90h {
    width: 90%;
    max-width: 90%;
    min-width: 90%;
    height: 95%;
}
.mx_95 {
    width: 95%;
    max-width: 95%;
    min-width: 95%;
    height: 95%;
}
.mx_100 {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
}

.mx_head {
    padding: 0px 10px;
    min-height: 50px;
    border-bottom: 1px solid #5487cb;
    font-size: 22px;
    line-height: 50px;
}
.mx_bg {
    color: #fff;
    background-colorx: #5487cb;
    background-color: #1f4485;
}
.mx_title {
  display: flex;
  flex-direction: row;
    padding: 0px 5px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
}
.mx_h01 {
  display: flex;
  background: #1f4485;
  color: #fff;
  flex-direction: row;
    padding: 0px 10px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
}
.mx_h02 {
  display: flex;
  background: #4b73b3;
  color: #fff;
  flex-direction: row;
    padding: 0px 10px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
}
.mx_h03 {
  display: flex;
  background: #6c8ab8;
  color: #fff;
  flex-direction: row;
    padding: 0px 10px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
}
.mx_h04 {
  display: flex;
  background: #697a8d;
  color: #fff;
  flex-direction: row;
    padding: 0px 10px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
}
.mx_h11 {
  display: flex;
  background: #6439F3;
  color: #fff;
  flex-direction: row;
    padding: 0px 10px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
}
.mx_h12 {
  display: flex;
  background: #99D9F3;
  color: #fff;
  flex-direction: row;
    padding: 0px 10px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
}
.mx_h13 {
  display: flex;
  background: #7BDC3D;
  color: #fff;
  flex-direction: row;
    padding: 0px 10px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
}
.mx_head {
  display: flex;
  background: #1f4485;
  color: #fff;
  flex-direction: row;
    padding: 8px 10px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
}
.mx_close {
    padding: 0px 5px;
    line-height: 50px;
    cursor: pointer;
}
.mx_content {
  display: flex;
  flex-direction: column;
    padding: 0px;
    min-height: 50px;
    overflow: auto;
}
.mx_foot {
    padding: 5px 5px;
    min-height: 50px;
    border-top: 1px solid #ff9900;
}
.mx_body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: space-between;
}

.md_dlg_container {
    display: flex;
    display: -webkit-flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1020;
    overflow: hidden;
    background-color: rgba(75,75,75, 0.5);
}

.md_dlg {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    position: relative;
    background:#fff;
    min-width: 400px;
    min-height: 250px;
    border: 1px solid #555;
    border-radius: 4px;
    z-index: 1020;

    box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
}
.md_md {
    width: 80%;
    height: 70%;
}
.md_center {
    left: 300px;
    top: 200px;
}
.md_top {
    flex: 0;
    padding: 0px 5px;
    border-bottom: 1px solid #ff9900;
    min-height: 50px;
}
.md_body {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    padding: 5px 5px;
    min-height: 200px;
}
.md_foot {
    flex: 0;
    padding: 5px 5px;
    border-top: 1px solid #ff9900;
}
.md_full {
    height: 100%;
    width: 100%;
}
.h500 {
    height: 500px;
}
.btn_link {
    color: #3755b0;
    text-decoration: none;
    cursor: pointer;
}
.btn_link:hover {
    color:#ff9900;
}
.mx-close-btn {
  margin: 0px;
  padding: 0px;
  width: 30px;
  height: 50px;
  color: #fff;
  text-align: center;
  font-size:22px;
  cursor: pointer;
}

.mx-close-btn:hover {
  color: #d7851f;
}
.Toastify__toast.toast-rounded {
    border-radius: 5px;
}