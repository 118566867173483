.btn_drag {
  background: #800;
  color: #fff;
  margin: 5px 0px;
  padding: 0px;
  width: 125px;
  height: 65px;
  line-height: 30px;
  border: 1px solid #ff9900;
  text-align: center;
}
.btn_row {
  margin: 5px 0px;
  padding: 0px;
  height: 65px;
  line-height: 65px;
}
.btn_grp {
  margin: 0px 5px;
  padding: 0px;
  background: #008;
  color: #fff;
  border: 1px solid #ff9900;
  text-align: center;
}
.btn_grp:hover {
  background: #ff9900;
  color: #fff;
}
.btn_pos {
  margin: 0px 5px;
  padding: 0px;
  background: #080;
  color: #fff;
  border: 1px solid #ff9900;
  text-align: center;
}
.btn_pos:hover {
  background: #ff9900;
  color: #fff;
}

.btn-hw-green {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #ffffff;
  color: #008800;
  border: 1px solid #008800;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}
.btn-hw-green:hover {
  background: #008800;
  color: #ffffff;
  border: 1px solid #008800;
  box-sizing: border-box;
}
.btn-hw-blue {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #ffffff;
  color: #000088;
  border: 1px solid #000088;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}
.btn-hw-blue:hover {
  background: #000088;
  color: #ffffff;
  border: 1px solid #000088;
  box-sizing: border-box;
}
.btn-hw-red {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #ffffff;
  color: #880000;
  border: 1px solid #880000;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}
.btn-hw-red:hover {
  background: #88000000;
  color: #ffffff;
  border: 1px solid #880000;
  box-sizing: border-box;
}
.btn:focus {
  outline: none;
}
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

.hide {
    display: none;
}
