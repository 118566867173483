.App {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cdi_xmnu {
  width: 60px;
  padding: 0px 2px;
  background-color: #f5f5f5;
  color: #222;
  /*
  background-color: #eff1f9;
  color: #222;
   */
  border-right: 1px #ccc solid;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}
.cdi_xmnu .xmnu_tab {
  padding: 0px;
  margin: 0px;
  height: 50px;
  border-bottom: 1px #ccc solid;
}
.cdi_xmnu .xmnu_tab i {
  /*
  color: rgba(0,0,0,0.54);
   */
  color: rgba(0,0,0,0.54);
}
.cdi_xmnu .xmnu_tab .select i  {
  color: #fff;
}
.cdi_xmnu .xmnu_tab:hover {
  background-color: #ff9900;
  color: #fff;
}
.cdi_xmnu .xmnu_tab:hover i {
  color: #fff;
}

.mtrl_mnu_sub {
  position: absolute;
  top: 50px;
  left: 60px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
      0 13px 19px 2px rgba(0, 0, 0, 0.14),
      0 5px 24px 4px rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 256px;
  z-index: 999;
}
.mtrl_mnu_title {
  align-items: center;
  cursor: crosshair;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  position: relative;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  border-bottom: 1px solid #fff;
  color: #fff;

  background: rgb(63, 76, 107); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(63,76,107,1) 0%, rgba(63,76,107,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(63,76,107,1) 0%,rgba(63,76,107,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(63,76,107,1) 0%,rgba(63,76,107,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f4c6b', endColorstr='#3f4c6b',GradientType=0 ); /* IE6-9 */
}
.mtrl_mnu_titlex {
  align-items: center;
  cursor: crosshair;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  position: relative;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  border-bottom: 1px solid #fff;
  color: #fff;

  background: rgb(169,3,41); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(169,3,41,1) 0%, rgba(143,2,34,1) 44%, rgba(109,0,25,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(169,3,41,1) 0%,rgba(143,2,34,1) 44%,rgba(109,0,25,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(169,3,41,1) 0%,rgba(143,2,34,1) 44%,rgba(109,0,25,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a90329', endColorstr='#6d0019',GradientType=0 ); /* IE6-9 */
}
.mtrl_mnu_item md-icon {
  color: #fff;
}
.mtrl_mnu_item {
  align-items: center;
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: normal;
  position: relative;
  transition: background 0.15s linear;
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
}
.mtrl_mnu_item:hover,
.mtrl_mnu_item:focus {
  color: #fff;
  background-color: #ff9900;
}
.mtrl_mnu_item.selected {
  color: rgb(16, 108, 200);
}

.mtrl_mnu_bg {
  color: #3F4C6B;
  background: #eff1f9;
}
.mtrl_mnu_bgx {
  color: #fff;

  background: rgb(63, 76, 107); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(63,76,107,1) 0%, rgba(63,76,107,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(63,76,107,1) 0%,rgba(63,76,107,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(63,76,107,1) 0%,rgba(63,76,107,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f4c6b', endColorstr='#3f4c6b',GradientType=0 ); /* IE6-9 */
}

.mnu_itemx {
  color: #3F4C6B;
  background: #f0f0f0;
  border-bottom: 1px solid #DDDDDD;
}
.mnu_itemx:hover {
  color: #ff9900;
  background: #fafafa;
}
.mnu_item {
  padding: 8px 8px;
  color: #f0f0f0;
  background: #000;
  border-bottom: 1px solid #555;
}
.mnu_item:hover {
  color: #080;
  background: #111;
}
.mnu_item_head {
  color: #ff9900;
}
.mnu-link {
  color: #c1d0f5;
}

.mnu-btn {
  margin: 5px;
  padding: 5px;
  height: 40px;
  line-height: 15px;
  background-color: #222;
  color: #dadada;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #555;
  border-radius: 5px;
}

.mnu-btn:hover {
  background-color: #444;
  color: #ff9900;
}

.hide {
  display: none;
}

.mnu-grid {
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(2, 1fr);
}

.ws_item {
  padding: 15px 25px;
  color: #222;
  border-bottom: 1px dashed #555;
}
.ws_item:hover {
  color: #fff;
  background: #080;
}
.ws_item_head {
  color: #8f2a2a;
}
.ws-link {
  color: #c1d0f5;
}
