@font-face {
  font-family: 'Brown';
  src: url('font/Brown-Regular.ttf');
}
@font-face {
  font-family: 'Brown-Bold';
  src: url('font/Brown-Bold.ttf');
}
@font-face {
  font-family: 'Colby';
  src: url('font/Colby.ttf');
}
@font-face {
  font-family: 'FeltThat';
  src: url('font/Felt-That.ttf');
}
html, body {
  font-family: 'Brown', Fallback, sans-serif;
  font-size:14px;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
#root {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.wnd-fill {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.web-app {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  -webkit-flex: 1;
}
.web-top {
  padding: 0px 15px;
  border-bottom: 0px solid #000088;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.web-main {
  margin: 0px;
  padding: 0px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.web-body {
  padding: 0px;
}
.web-content {
  padding: 0px;
  background: #fff;
  min-height: 250px;
}
.web-container {
  padding-top: 5px;
  width: 100%;
  background: #fff;
}
.section {
  padding: 20px 25px;
  min-height: 300px;
  background: #fff;
}
.web-top-btn {
  margin: 0px;
  padding: 0px;
  width: 75px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fff;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  font-size: 18px;
}
.web-top-btn:hover {
  background: #f99000;
  color: #fff;
}
.btnmob {
  background-color: #fff;
  border: 1px solid #1b64b7;
  border-radius: 5px;
  color: #222;
  margin: 3px 5px;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
}
.xbtnmob:hover {
  background-color: #008CBA;
  color: #fff;
}
.btnmob:active {
  background-color: #ccc;
  box-shadow: 0 5px #555;
  transform: translateY(4px);
}

.txtmob:active {
  color: #ccc;
  transform: translateY(4px);
}

.btn {
  border: 0px solid #ccc;
  border-radius: 5px;
  min-width: 100px;
  height: 35px;
  min-height: 35px;
  background: transparent;
}
.btn-hw-green {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #ffffff;
  color: #008800;
  border: 1px solid #008800;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}
.btn-hw-green:hover {
  background: #008800;
  color: #ffffff;
  border: 1px solid #008800;
  box-sizing: border-box;
}
.btn-hw-blue {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  min-height: 50px;
  background: #ffffff;
  color: #000088;
  border: 1px solid #000088;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}
.btn-hw-blue:hover {
  background: #000088;
  color: #ffffff;
  border: 1px solid #000088;
  box-sizing: border-box;
}
.btn-hw-red {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #ffffff;
  color: #880000;
  border: 1px solid #880000;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}
.btn-hw-red:hover {
  background: #880000;
  color: #ffffff;
  border: 1px solid #880000;
  box-sizing: border-box;
}
.btn-hw-orange {
  margin: 0px 5px;
  padding: 5px 10px;
  min-width: 50px;
  background: #ffffff;
  color: #ff9900;
  border: 1px solid #ff9900;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
}
.btn-hw-orange:hover {
  background: #ff9900;
  color: #ffffff;
  border: 1px solid #ff9900;
  box-sizing: border-box;
}
.btn-hw-pay {
  margin: 5px 5px;
  padding: 5px 10px;
  min-width: 50%;
  min-height: 100px;
  line-height: 100px;
  background: #ffffff;
  color: #008800;
  border: 1px solid #008800;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
}
.btn-hw-pay:hover {
  background: #008800;
  color: #ffffff;
  border: 1px solid #008800;
  box-sizing: border-box;
}

.divider_section {
  height: 20px;
  background: #eee;
}
.divider_orange {
  border-top: 1px solid #f99000;
}
.divider_blue {
  border-top: 1px solid #000088;
}
.divider_green {
  margin: 0px;
  padding: 0px;
  border-top: 1px solid #008800;
}
.horz_red {
  margin: 0px;
  padding: 0px;
  border-left: 1px solid #880000;
}
.vert_orange {
  margin: 0px;
  padding: 0px;
  border-left: 1px solid #f99000;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.mgu {
  margin: 0px auto;
  padding: 0px 10px;
  width: 1024px;
  font-weight: bold;
}
.mgu-grid {
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}
.mgu-grid .mg-body {
  padding: 0px;
  border: 0px solid #ccc;
  border-radius: 5px;
}
.mgu-grid .mg-body:hover {
  border: 0px solid #ff9900;
}

.mgv {
  margin: 0px auto;
  padding: 0px 10px;
  width: 1024px;
  font-weight: bold;
}
.mgv-grid {
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}
.mgv-grid .mg-body {
  padding: 0px;
  border: 0px solid #ccc;
  border-radius: 5px;
}
.mgv-grid .mg-body:hover {
  border: 0px solid #ff9900;
}

.mpg-grid {
  margin: 0px;
  padding: 10px 0px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 1fr);
  overflow: hidden;
}
.dlt-grid {
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
}

.cart-badge {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 40px;
  height: 40px;
  line-height: 30px;
  padding: 5px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  background: #fafafa;
  color: #222;
}
.cart-btn-icon {
  margin: auto;
  padding: 0px 5px;
  line-height: 40px;
  font-size: 24px;
}

.cart-btn {
  position:relative;
  margin: 5px 0px;
  padding: 0px 5px;
  border: 0px solid #ccc;
  border-radius: 25px;
  min-width: 150px;
  height: 45px;
  line-height: 45px;
  min-height: 45px;
  background: #fee260;
  color: #000;
  text-align: center;
  cursor: pointer;
}

.mg-btn {
  border: 0px solid #ccc;
  border-radius: 25px;
  min-width: 150px;
  height: 45px;
  line-height: 45px;
  min-height: 45px;
  background: #fee260;
  color: #000;
  text-align: center;
  cursor: pointer;
}
.ina-btn {
  padding: 0px 5px;
  border: 0px solid #ccc;
  border-radius: 20px;
  min-width: 110px;
  height: 32px;
  line-height: 32px;
  min-height: 32px;
  background: #fee260;
  color: #000;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
}
.mp-btn {
  padding: 0px 5px;
  border: 0px solid #ccc;
  border-radius: 25px;
  min-width: 110px;
  height: 42px;
  line-height: 42px;
  min-height: 42px;
  background: #fee260;
  color: #000;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
}
.mfull-btn {
  padding: 10px 0px;
  border: 0px solid #ccc;
  border-radius: 25px;
  width: 100%;
  min-height: 40px;
  background: #fee260;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.mpay-btn {
  padding: 10px 0px;
  border: 0px solid #ccc;
  border-radius: 25px;
  width: 100%;
  min-height: 60px;
  line-height: 60px;
  background: #fee260;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.bhollow-btn {
  border: 2px solid #000;
  border-radius: 35px;
  min-width: 350px;
  height: 75px;
  line-height: 75px;
  min-height: 75px;
  color: #000;
  text-align: center;
  cursor: pointer;
}
.whollow-btn {
  border: 2px solid #fff;
  border-radius: 35px;
  min-width: 350px;
  height: 75px;
  line-height: 75px;
  min-height: 75px;
  color: #000;
  text-align: center;
  cursor: pointer;
}
.whome-btn {
  border: 2px solid #fff;
  border-radius: 35px;
  min-width: 300px;
  height: 65px;
  line-height: 65px;
  min-height: 65px;
  background: #fff;
  color: #000;
  text-align: center;
  cursor: pointer;
}
.fab-btn {
  position:absolute;
  right: 20px;
  bottom: 20px;
  margin: 0px;
  padding-top: 20px;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
  color: #222;
  background-color: rgb(244, 59, 36);
  background: #fee260;
  font-size: 24px;
  font-weight: 900;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  z-index: 1150;
}
.fab-btn_shadow {
  margin: 3px;
  box-shadow: 0px 0px 8px rgb(244, 59, 36);
  box-shadow: 0px 0px 8px #fee260;
}

.xfab-btn {
  position:absolute;
  right: 20px;
  bottom: 20px;
  margin: 0px;
  padding: 0px 5px;
  border: 0px solid #800;
  border-radius: 45px;
  width: 65px;
  height: 65px;
  line-height: 65px;
  min-height: 65px;
  background: #fff;
  color: #222;
  text-align: center;
  cursor: pointer;
  z-index: 1150;
}
.fab-btn:hover {
  background: #ff9900;
  color: #fff;
}
.xfab-btn:hover {
  background: #ff9900;
  color: #fff;
}
.gray-btn {
  padding: 5px 0px;
  border: 0px solid #ccc;
  border-radius: 25px;
  width: 150px;
  min-height: 35px;
  line-height: 35px;
  background: #ccc;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.mg-top {
  margin: 0px;
  padding: 0px;
  position: absolute;
  z-index: 90;
}
.mg-img {
  margin: 0px;
  padding: 0px;
  height: 120px;
}

.mg-body {
  margin: 0px auto;
  padding: 0px 10px;
  width: 1024px;
  font-weight: normal;
  min-width: 950px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 3px;
  z-index: 95;
}
.mk-body {
  margin: 0px;
  padding: 0px 10px;
  font-weight: normal;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 3px;
  z-index: 95;
}

.mpg {
  margin: 0px;
  padding: 10px 10px;
  border-bottom: 0px #cc9e9e solid;
}
.mp-top-grid {
  margin: 0px auto;
  padding: 20px 10px;
  width: 1024px;
  font-weight: bold;
  text-align: center;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
}

.mh-center-left {
  position: relative;
  margin: 0px;
  padding: 0px;
  padding-top: 140px;
}
.mh-center-right {
  position: relative;
  margin: 0px;
  padding: 0px;
  padding-top: 140px;
}
.mp-center-left {
  position: absolute;
  margin: 0px;
  padding: 0px;
  top: 50%;
  left: 20px;
}
.mp-center-right {
  position: absolute;
  margin: 0px;
  padding: 0px;
  top: 50%;
  right: 20px;
}

.mp-top-menu {
  margin: 0px auto;
  padding: 20px 10px;
  width: 1024px;
  font-weight: bold;
  text-align: center;
  overflow: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  cursor: grab;
}
.mp-top-menu::-webkit-scrollbar {
  display: none;
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.mp-top-menu::-webkit-scrollbar-thumb {
  background: #fee260;
}

.mp-top-item {
  margin: 0px;
  padding: 0px 10px;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  word-spacing: 1px;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

.plu_check {
  margin: 0px;
  padding: 0px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background: #fee260;
  color: #000;
  cursor: pointer;
  border-radius: 12px;
}
.web-link {
  color: #228ad9;
  cursor: pointer;
}
.web-link:hover {
  color: #ff9900;
}
.lightgrey {
  color: #ecebe6;
}
.darkgrey {
  color: #989595;
}
.nrof-text {
  color: #000;
}

.body-text {
  margin: 0px auto;
  padding: 0px;
  width: 650px;
  cursor: pointer;
  font-family: Brown, Fallback, sans-serif;
  font-size: 18px;
}
.dlt-body {
  margin: 0px auto;
  padding: 0px 10px;
  font-weight: normal;
  width: 950px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  z-index: 95;
}
.wp {
  margin: 0px auto;
  padding: 0px 10px;
  font-weight: normal;
  width: 1024px;
}
.wpx {
  margin: 0px auto;
  padding: 0px 10px;
  font-weight: normal;
  width: 1200px;
}
.plutxt {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
}
.plutxt2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}
.pludesc {
  font-size: 16px;
  font-weight: 100;
  line-height: 24px;
  max-width: 450px;
  white-space: initial;
}
.txtwrap {
  white-space: initial;
}
.plurow {
  padding: 25px 20px;
}
.pxrow {
  padding: 0px 20px;
}
.mcom-start {
  width: 90%;
  object-fit: contain;
}
.mcom_mmnu {
  color: #222;
}

.txt-head {
  font-weight: 700;
  text-align: center;
  padding-bottom: 10px;
}
.txt-stycke {
  text-align: center;
  padding-bottom: 20px;
}
.places-input {
  text-align: left;
  font-size: 18px;
  padding: 3px 5px;
  width: 450px;
  height: 45px;
  line-height: 45px;
  border-bottom: 0px solid #000;
  border-radius: 8px 8px 0px 0px;
}
.places-info {
  background: #eee;
  color: #222;
  text-align: left;
  font-size: 18px;
  padding: 2px 5px;
  width: 450px;
  height: 35px;
  line-height: 35px;
  border: 2px solid #000;
  border-top: 1px dashed #222;
  border-radius: 0px 0px 8px 8px;
}
.places-infox {
  background: #eee;
  color: #222;
  text-align: left;
  font-size: 18px;
  padding: 2px 5px;
  width: 100%;
  height: 35px;
  line-height: 35px;
  border: 1px solid #ccc;
  border-top: 1px dashed #ccc;
  border-radius: 0px 0px 8px 8px;
}
.kvitto-logo {
  padding: 15px 5px;
  text-align: center;
  background: #222;
}
.zipauto {
  top: 250px;
  left: 75px;
}
.zipinfo {
  padding: 5px 5px;
  width: 450px;
  line-height: 20px;
  height: 55px;
  background: #d29292;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.zipinfo_deliver {
  padding: 5px 5px;
  width: 100%;
  line-height: 20px;
  height: 55px;
  background: #d29292;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
}

@media (min-width: 800px) {
  .mcom-mobile {
    display: none;
  }
  .fab-btn {
    display: none;
  }
}
@media (max-width: 800px) {
  .places-info {
    width: 100%;
  }
  .zipinfo {
    width: 100%;
  }
  .zipauto {
    top: 150px;
    left: 5px;
  }
  .mcom-mmenu {
    display: none;
  }
  .mcom-mobile {
    padding: 8px 0px;
  }
  .plutxt {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }
  .plutxt2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }
  .plurow {
    padding: 10px 8px;
  }
  .pxrow {
    padding: 5px 5px;
  }
  .pxmrow {
    padding-top: 3px;
  }
  .pxmrow .txt_16 {
    font-size: 18px;
  }

  .mh-center-left {
  }
  .mh-center-right {
  }
  .cart-btn {
    display: none;
  }
  .mp-top-menu {
    width: 100%;
  }
  .mp-btn {
    width: 100%;
  }
  .pay-btn {
    width: 100%;
  }
  .mpg {
    padding: 5px 5px;
  }
  .mpg-grid {
    width: 365px;
    grid-template-columns: repeat(3, 1fr);
  }
  .wp {
    margin: 0px;
    width: auto;
    padding: 0px 10px;
  }
  .wpx {
    margin: 0px auto;
    padding: 0px 10px;
    font-weight: normal;
    width: 100%;
  }
  .mp-top-grid {
    margin: 0px auto;
    padding: 20px 10px;
    width: 100%;
    overflow: auto;
    font-weight: bold;
    text-align: center;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
  }
  .mg-body {
    margin: 0px;
    padding: 0px 10px;
    min-width: 0px;
    font-weight: normal;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 3px;
    z-index: 95;
  }
  .mgv {
    margin: 0px;
    padding: 0px 10px;
    width: 100%;
    font-weight: bold;
  }
  .mgv-grid {
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
  .dlt-body {
    margin: 0px;
    padding: 0px 10px;
    font-weight: normal;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 0px;
    z-index: 95;
  }
  .dlt-grid {
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(1, 1fr);
  }
}
