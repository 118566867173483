html {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Garamond', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  min-height: 100%;
}
bodyx {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
               Roboto, Oxygen-Sans, Ubuntu, Cantarell,
               "Helvetica Neue", sans-serif;
  height: 100%;
  min-height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  display: flex;
  height: 100%;
  min-height: 100%;
}
